import css from "./Home.module.css";

import title_img from "../../images/title_img.png";

import stanza from "../../images/projects/stanza.png";
import robophoto from "../../images/projects/robophoto.png";
import zaar from "../../images/projects/zaar.png";
import cib from "../../images/projects/cib.png";
import hacc from "../../images/projects/hacc.png";

import nyc from "../../images/adventures/nyc.jpg";
import la from "../../images/adventures/la.jpg";
import mexico from "../../images/adventures/mexico.jpg";
import lisbon from "../../images/adventures/lisbon.jpg";
import pvd from "../../images/adventures/pvd.jpg";
import sf from "../../images/adventures/sf.jpg";
import oc from "../../images/adventures/oc.jpg";
import budapest from "../../images/adventures/budapest.png";

import bdh from "../../images/press/bdh.png";
import divein from "../../images/press/divein.png";
import riinno from "../../images/press/riinno.png";

import ProjectBox from "../ProjectBox/ProjectBox";
import AdvenureBox from "../AdventureBox/AdventureBox";

const Home = () => {
  const zaarPress = [
    {
      img: bdh,
      url: "https://www.browndailyherald.com/article/2021/02/two-seniors-launch-new-buying-and-selling-platform-zaar",
    },
    {
      img: riinno,
      url: "https://www.bizjournals.com/rhodeisland/inno/stories/profiles/2021/03/02/brown-risd-students-launch-new-online-marketplace.html",
    },
    {
      img: divein,
      url: "https://podcasts.apple.com/us/podcast/zaar-x-startup-of-the-month/id1501107299?i=1000515905840",
    },
  ];

  return (
    <div>
      <div className={css.topBar} />
      <div className={css.titlePage}>
        <img src={title_img} alt="" className={css.titleImg} />
        <div className={css.titleTextBox}>
          <p className={css.hiText}>Hi there!</p>
          <p className={css.nameText}>I'm Madeline Griswold.</p>
          <svg
            className={css.line}
            xmlns="http://www.w3.org/2000/svg"
            width="379"
            height="6"
            viewBox="0 0 379 6"
            fill="none"
          >
            <path
              d="M3 3H376"
              stroke="#2D2B28"
              stroke-width="5"
              stroke-linecap="round"
            />
          </svg>
        </div>
      </div>

      <div className={css.bioPage}>
        <p className={css.bioText}>
          I'm an early-stage startup engineer and founder, all about building
          delightful products from 0 to 1. I co-founded & sold a website for
          gen-AI professional headshots and founded a secondhand marketplace for
          college students. My startup work experience consists of
          product-focused engineering and leadership roles. I've worked as
          consultant for several startups, advising on growth & product
          strategy, as well as helping with branding, design, and engineering.
        </p>
      </div>

      <div className={css.tanBg}>
        <div className={css.projects} id="cool">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              width: "100%",
            }}
          >
            <p className={css.projectsTitle}>Things i've built</p>
          </div>
          <ProjectBox
            title='Stanza'
            role="Founder"
            link={"https://www.stanzaapp.com/"}
            imgUrl={stanza}
            description={
              "An app for AI-generated podcasts, about any topic"
            }
          />
          <ProjectBox
            title={"Robophoto"}
            role="Co-founder"
            link={"https://www.robophoto.io/"}
            imgUrl={robophoto}
            description={
              "A website for professional headshots with generative AI"
            }
          />
          <ProjectBox
            title={"Zaar"}
            imgUrl={zaar}
            description={
              "An online marketplace for college students to buy and sell secondhand items (clothes, school supplies, furniture, etc) within their campus"
            }
          >
            <p className={css.pressTitle}>In the press:</p>
            <div className={css.pressBox}>
              {zaarPress.map((press) => (
                <a
                  href={press.url}
                  target="_blank"
                  rel="noreferrer"
                  key={press.url}
                >
                  <div className={css.pressItem}>
                    <img src={press.img} height="17" alt="" />
                  </div>
                </a>
              ))}
            </div>
          </ProjectBox>
          <ProjectBox
            title={"Corona is Blind"}
            imgUrl={cib}
            description={`A 1-week virtual dating experience designed to help college students meet one another during quarantine (entry fees raised $1000's for covid relief). `}
          >
            <p className={css.pressTitle}>Testimonials:</p>
            <p className={css.testimonial}>
              “This has been super fun and absolutely a highlight for me during
              this quarantine."
            </p>
            <p className={css.testimonial}>
              “Last Sunday marks one month since the BIG REVEAL, and X and I
              have become very good friends. I am so glad I met him, which
              wouldn't have happened without engaging in this process.”
            </p>
          </ProjectBox>
          <ProjectBox
            title={"Hashtag Anyone Can Code"}
            imgUrl={hacc}
            description={
              "A nonprofit that taught elementary and middle school students the fundamentals of Computer Science."
            }
          />
        </div>
      </div>

      <div className={css.ted}>
        <div className={css.tedTitles}>
          <p className={css.tedSubtitle}>In 2019, I delivered the TEDx Talk</p>
          <p className={css.tedTitle}>How Computer Science Made Me Brave</p>
        </div>
        <div style={{ zIndex: 10 }}>
          <div className={css.tedRed}>
            <iframe
              width="560"
              height="315"
              className={css.video}
              src="https://www.youtube.com/embed/nZ1ehJqXor0"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
            <p className={css.tedContent1}>
              Named one of{" "}
              <span style={{ fontWeight: 700 }}>
                “18 Must-Watch Coding TED Talks”
              </span>{" "}
              by Columbia Engineering
            </p>
            <p className={css.tedContent2}>
              and viewed over{" "}
              <span style={{ fontWeight: 700 }}>85,000 times</span> to date
            </p>
            <div className={css.tedOverlay} />
          </div>
        </div>
      </div>

      <div className={css.adventure}>
        <div className={css.page + " " + css.adventurePage}>
          <div className={css.adventureHeaderBox}>
            <p className={css.tedSubtitle}>Outside of startup stuff,</p>
            <p className={css.tedTitle}>I spent two years nomading</p>
          </div>

          <AdvenureBox
            description={"Places I've called home..."}
            images={[
              { imageUrl: nyc, caption: "New York City, NY" },
              { imageUrl: mexico, caption: "Mexico City, Mexico" },
              { imageUrl: lisbon, caption: "Lisbon, Portugal" },
              { imageUrl: sf, caption: "San Francisco, CA" },
              { imageUrl: la, caption: "Los Angeles, CA" },
              { imageUrl: budapest, caption: "Budapest, Hungary" },
              { imageUrl: pvd, caption: "Providence, RI" },
              { imageUrl: oc, caption: "Orange County, CA" },
            ]}
          />

          {/* <p className={css.adventureDescription}>
            Interested in nomading? Here are some thoughts...
          </p> */}
        </div>
      </div>

      <div className={css.greenBg}>
        <div className={css.footer}>
          <p className={css.touchText}>Want to connect?</p>
          <a href="mailto: mgriswold99@gmail.com" className={css.noUnderline}>
            <button className={css.contactButton + " " + css.noUnderline}>
              Contact Me
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
