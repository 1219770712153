import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./views/Home/Home";
import Cryptograms from "./views/Cryptograms/Cryptograms";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/me" element={<Home />} />
        <Route path="/scramble" element={<Cryptograms />} />
        <Route path="/cryptograms" element={<Cryptograms />} />
      </Routes>
    </Router>
  );
}

export default App;
